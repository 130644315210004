"use client";

import { AntdRegistry } from '@ant-design/nextjs-registry';
import { SellerProvider } from "@context/sellerContext";
import { LoadingProvider } from "@context/loadingContext"

export const Providers = ({ children }) => {
  return (
    <AntdRegistry>
      <LoadingProvider>
        <SellerProvider>
          {children}
        </SellerProvider>
      </LoadingProvider>
    </AntdRegistry>
  );
}
