'use client';

import Image from "next/image";

const { createContext, useEffect, useState } = require("react");
export const LoadingContext = createContext({ loading: { isLoading: false, setIsLoading: () => { } } })
const LogoImage = require('../assets/img/logo/logo-blue.png')
export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false)
  return (
    <LoadingContext.Provider value={{ loading: { isLoading: loading, setIsLoading: setLoading } }}>
      {loading && <div id="cont_loading">
        <Image className="img" src={LogoImage} alt="logo" />
        <h4>Cargando</h4>
      </div>
      }
      {children}
    </LoadingContext.Provider>
  )
}
