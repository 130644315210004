import { useRouter, usePathname } from 'next/navigation';
import { message } from "antd";
import { useContext } from 'react';
import { LoadingContext } from '@context/loadingContext';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export const useHttp = () => {
  const { loading: { setIsLoading } } = useContext(LoadingContext);
  const router = useRouter();
  const pathname = usePathname()

  const executeActions = (actions) => {
    actions.forEach(action => {
      if (action.type === 'redirect') {
        if (pathname !== action.url) router.push(action.url);
      }
    });
  };

  const httpRequest = async ({ url, method, data = null, headers = {}, options }) => {
    const { pathParams, callback, dontShowMessage, dontShowLoading, typeData, forceExecuteCallback = false } = options || {};
    if (!dontShowLoading) setIsLoading(true)
    let responseContent = null;
    try {
      const options = {
        method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-From-Path': pathname,
          ...headers
        }
      };
      if (data) {
        options.body = JSON.stringify(data);
      }

      if (typeData === 'formData') {
        options.headers = {
          ...options.headers,
          'Content-Type': ''
        }
        options.body = data;
      }

      if (pathParams) {
        url = `${url}/${pathParams}`;
      }

      const resFetch = await fetch(`${BASE_URL}/${url}`, options);
      const res = await resFetch.json();

      if (res.responseContent) responseContent = res.responseContent;

      if (resFetch.status === 303 && responseContent.redirectUrl) {
        window.open(responseContent.redirectUrl, '_blank');
        return;
      }

      if (!resFetch.ok || res.error) {
        if (res.actions) executeActions(res.actions);
        throw new Error(res.msg);
      }

      if (res.msg && !dontShowMessage) message.success(res.msg, 5);
      if (res.actions) executeActions(res.actions);
      if (callback) callback(responseContent);
      return responseContent;
    } catch (error) {
      if (!dontShowMessage) message.error(error.message, 5);
    } finally {
      setIsLoading(false)
    }
    if (forceExecuteCallback && callback) callback(responseContent);
  };

  const GET = async ({ url, headers, options }) => {
    return httpRequest({ url, method: 'GET', headers, options });
  };

  const POST = async ({ url, headers, data, options }) => {
    return httpRequest({ url, method: 'POST', data: data, headers, options });
  };

  const PUT = async ({ url, headers, data, options }) => {
    return httpRequest({ url, method: 'PUT', data: data, headers, options });
  };

  const DELETE = async ({ url, headers, options }) => {
    return httpRequest({ url, method: 'DELETE', headers, options });
  };

  return {
    GET,
    POST,
    PUT,
    DELETE
  };
};
